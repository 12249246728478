import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="metamind"></a><h3>METAMIND</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80456.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <a id="metamind-requirements"></a><h6>Requirements</h6>
To qualify to become a metamind, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge
(psionics)</a> 8 ranks, <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a> 4 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feat:</span> <a href="psionicFeats.html#psicrystal-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicrystal
Affinity</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Manifester
level 4th.</p>
    <h6>Class Skills</h6>
The metamind&#8217;s class skills are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a> (Wis), <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a>*
(Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft
    </a>(any) (Int), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a>, and <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>
(Int).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier.</p>
    <a id="table-the-metamind"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Metamind</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "30%"
          }}>Special
          </th>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Free manifesting 1st, 3/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Cognizance psicrystal 5 points
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Free manifesting 2nd, 3/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Cognizance psicrystal 7 points
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Free manifesting 3rd, 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Cognizance psicrystal 9 points
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Free manifesting 4th, 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>Cognizance psicrystal 11 points
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Free manifesting 5th, 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">Font of power
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="metamind-class-features"></a><h6>Class Features</h6>
All the following are class features of the metamind prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Metaminds gain no proficiency with any weapon or armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> At every
even-numbered level, a metamind gains additional power points per day
and access to new powers as if he had also gained a level in whatever
manifesting class he belonged to before he added the prestige class. He
does not, however, gain any other benefit a character of that class
would have gained (bonus feats, metapsionic or item creation feats, and
so on). This essentially means that he adds the level of metamind to
the level of whatever manifesting class the character has, then
determines power points per day, powers known, and manifester level
accordingly.
If a character had more than one manifesting class before he became a
metamind, he must decide to which class he adds the new level of
metamind for the purpose of determining power points per day, powers
known, and manifester level.</p>
    <a id="metamind-free-manifesting"></a><p><span style={{
        "fontWeight": "bold"
      }}>Free Manifesting (Ps):</span>
At 1st level, a metamind can manifest any 1st-level power he knows for
free (without spending power points) three times per day.
At higher levels, a metamind gains the ability to freely manifest
additional higher-level powers: three 2nd-level powers per day at 3rd
level; one 3rd-level power per day at 5th level; one 4th-level power
per day at 7th level; and one 5th-level power per day at 9th level.
This benefit applies only to the power point cost of an unaugmented
power. Points spent to augment a power and an experience point cost (if
any) must be paid as normal.</p>
    <a id="metamind-cognizance-psicrystal"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cognizance Psicrystal (Ex):</span>
At 2nd level, a metamind masters the trick of storing excess power
points in a psicrystal. The psicrystal is now treated as a cognizance
crystal capable of storing 5 power points, in addition to its
psicrystal abilities. At every even-numbered level, a metamind becomes
able to store an additional 2 power points in his psicrystal, to a
maximum of 11 points at 8th level.</p>
    <a id="metamind-font-of-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Font of Power (Ps):</span> A
10th-level metamind can act as a living cognizance crystal, producing
seemingly endless power points once per day, for up to 1 minute. His
eyes shine like tiny stars, and faint illumination seems to beam out of
his mouth and the end of each of his fingers. While so empowered, he
can manifest any of his powers without drawing from his power point
reserve. He finds the power points he needs welling up within his own
body.
If a metamind using this ability enters a metaconcert, his power point
reserve is accessed normally for the purpose of his contributing to the
pool. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      